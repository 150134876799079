export const CALCULATOR_STEPS = [
  {
    amount: 2,
    price: {
      ru: { month: 2900, quarter: 2755, halfYear: 2610, year: 2465 },
      ua: { month: 1060, quarter: 1007, halfYear: 954, year: 901 },
      kz: { month: 16656.44 , quarter: 15823.62, halfYear: 14990.80, year: 14157.97 },
    }
  },
  {
    amount: 5,
    price: {
      ru: { month: 3500, quarter: 3325, halfYear: 3150, year: 2975 },
      ua: { month: 1279, quarter: 1215, halfYear: 1151, year: 1087 },
      kz: { month: 20102.60, quarter: 19097.47, halfYear: 18092.34, year: 17087.21 },
    }
  },
  {
    amount: 7,
    price: {
      ru: { month: 4200, quarter: 3990, halfYear: 3780, year: 3570 },
      ua: { month: 1535, quarter: 1458, halfYear: 1382, year: 1305 },
      kz: { month: 24123.12, quarter: 22916.96, halfYear: 21710.81, year: 20504.65 },
    }
  },
  {
    amount: 10,
    price: {
      ru: { month: 5200, quarter: 4940, halfYear: 4680, year: 4420 },
      ua: { month: 1901, quarter: 1806, halfYear: 1711, year: 1616 },
      kz: { month: 29866.72, quarter: 28373.38, halfYear: 26880.05, year: 25386.71 },
    }
  },
  {
    amount: 15,
    price: {
      ru: { month: 6700, quarter: 6365, halfYear: 6030, year: 5695 },
      ua: { month: 2449, quarter: 2326, halfYear: 2204, year: 2082 },
      kz: { month: 38482, quarter: 36558, halfYear: 34634, year: 32710 },
    }
  },
  {
    amount: 20,
    price: {
      ru: { month: 8200, quarter: 7790, halfYear: 7380, year: 6970 },
      ua: { month: 2997, quarter: 2847, halfYear: 2697, year: 2548 },
      kz: { month: 47098, quarter: 44743, halfYear: 42388, year: 40033 },
    }
  },
  {
    amount: 25,
    price: {
      ru: { month: 10200, quarter: 9690, halfYear: 9180, year: 8670 },
      ua: { month: 3728, quarter: 3542, halfYear: 3355, year: 3169 },
      kz: { month: 58585, quarter: 55655, halfYear: 52726, year: 49797 },
    }
  },
  {
    amount: 30,
    price: {
      ru: { month: 12200, quarter: 11590, halfYear: 10980, year: 10370 },
      ua: { month: 4459, quarter: 4236, halfYear: 4013, year: 3790 },
      kz: { month: 70072, quarter: 66568, halfYear: 63065, year: 59561 },
    }
  },
  {
    amount: 40,
    price: {
      ru: { month: 15300, quarter: 14535, halfYear: 13770, year: 13005 },
      ua: { month: 5592, quarter: 5313, halfYear: 5033, year: 4753 },
      kz: { month: 87877, quarter: 83483, halfYear: 79089, year: 74696 },
    }
  },
  // { amount: 50, price: { month: 17500, quarter: 15750, year: 14580 } },
  // { amount: 60, price: { month: 19500, quarter: 17550, year: 16250 } },
]

export const CALCULATOR_MARKS = { ...CALCULATOR_STEPS.map(step => step.amount) }
