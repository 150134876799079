import React, { useState } from "react"
import styles from "./currency-selector.module.scss"
import { Animated } from "react-animated-css"

import flagRu from "../../../images/lang/ru.svg"
import flagUa from "../../../images/lang/ua.svg"
import flagKz from "../../../images/lang/kz.svg"

function getCurrencyFlag(currency) {
  switch (currency) {
    case "ru":
      return flagRu
    case "ua":
      return flagUa
    case "kz":
      return flagKz
    default:
      return ""
  }
}

export default function ({ currency, onChange, slim }) {
  const [showCurrencySelector, setShowCurrencySelector] = useState(false)
  return (
    <div
      className={`${styles.currency_selector} ${slim && styles.currency_selector_slim}`}
      onClick={() => {setShowCurrencySelector(!showCurrencySelector)}}
    >
      <img className={styles.currency_selector__flag} src={getCurrencyFlag(currency)} alt={""}/>
      <Animated
        animationInDuration={300}
        animationOutDuration={300}
        animationIn="fadeIn"
        animationOut="fadeOut"
        animateOnMount={false}
        isVisible={showCurrencySelector}>
        <div className={styles.currency_selector__dropdown}>
          <div
            className={
              `
                ${styles.currency_selector__dropdown_item}
                ${currency === "ru" && styles.currency_active}
              `
            }
            onClick={() => onChange("ru")}
          >
            <img src={flagRu} alt={""}/>
            <div>Рубли (₽)</div>
          </div>
          <div
            className={
              `
                ${styles.currency_selector__dropdown_item}
                ${currency === "ua" && styles.currency_active}
              `
            }
            onClick={() => onChange("ua")}
          >
            <img src={flagUa} alt={""}/>
            <div>Гривны (₴)</div>
          </div>
          <div
            className={
              `
                ${styles.currency_selector__dropdown_item}
                ${currency === "kz" && styles.currency_active}
              `
            }
            onClick={() => onChange("kz")}
          >
            <img src={flagKz} alt={""}/>
            <div>Тенге (₸)</div>
          </div>
        </div>
      </Animated>
    </div>
  )
}
