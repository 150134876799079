import React from "react"
import ReviewComponent from "../components/pages/main/review"
import AboutComponent from "../components/pages/main/about"
import HeroFeaturePage from "../components/pages/shared/hero/feature-page"
import SEO from "../components/seo"
import Layout from "../components/layout"
import ContactFormComponent from "../components/pages/shared/contact-form"
import CalculatorComponent from "../components/pages/franchise/calculator"
import Discount from "../components/pages/main/discount"
import FranchiseAccordionComponent from "../components/pages/franchise/calculator/accordion"
import Breadcrumb from '../components/controls/breadcrumbs';

const FranchisePage = (props) => {
  const crumbs = [
    { name: 'Главная', url: '/'},
    { name: 'Франшизам', url: ''}
  ];
  return (
    <Layout headerColor={"#03C4A4"} page={"franchise"}>

      <SEO
        title="CRM-система учета для франшиз, сетей и филиалов"
        keywords={[`CRM-система учета для франшиз, сетей и филиалов`, `разработка CRM-системы`]}
        description="Контроль работы филиалов и франчайзи в одном месте. Облачная CRM Параплан автоматизирует работу сетей и франшиз детских учебных центров."
        url={"https://paraplancrm.ru" + props.location.pathname}
      />

      <HeroFeaturePage
        name={"franchise"}
        title='CRM-система учета для франшиз, сетей и филиалов'
        htmlDescription='
        Систематизирует работу <span style="font-weight:600;">сети франчайзи в одной системе.</span> 
        Помогает <span style="font-weight:600;">управлять доступами</span>  из любой точки мира. 
        Собирает <span style="font-weight:600; ">аналитику</b> по каждому филиалу. </span>
        Обеспечивает <span style="font-weight:600;">прозрачность внутренних процессов.</span>
      '
        color='#03C4A4'
        btnDark={true} // Удалить после окончания акции бесплатное лето
        image={require("../images/cover/franchise.png")}/>

      <Breadcrumb crumbs={ crumbs } arrowcolor={"green"}/>
      <CalculatorComponent
        page={"franchise"}
        title='Расчитайте стоимость подписки и оплатите прямо сейчас'
      />
      {/*<FreeSummer topInd="100"/>*/}
      <Discount />
      <AboutComponent
        title='Управляйте данными нескольких филиалов в&nbsp;одном&nbsp;месте'
        description='Мы обеспечили филиалы всем необходимым. Сотрудники могут быстро проводить платежи, отмечать посещаемость, добавлять клиентов в пустые окна, делать рассылки, составлять отчеты и прочее.'
        image={require("../images/franchise/feat_1@2x.png")}
        imageWidth={720}
        imageHeight={470}
        bgSize={"hanging-circle"}
        reverse={true}
        color='#6D20D2'/>

      <AboutComponent
        title='Автоматизируйте многоэтапную работу и верните себе время'
        description='Система Параплан берет на себя большую часть ручной работы ваших сотрудников. Автоматизированные регистрации, готовые скидки и быстрое распределение клиентов помогут эффективно справляться с ежедневными задачами.'
        image={require("../images/franchise/feat_2@2x.png")}
        imageWidth={673}
        imageHeight={340}
        bgSize={"hanging-circle"}
        color='#E75D49'/>

      <ReviewComponent author={"Команда Параплан"}>
        За последние 20 лет практически все отрасли бизнеса стали значительно эффективнее благодаря технологиям. Однако,
        сектор профессиональных услуг остался в стороне. Здесь сотрудники до сих пор пользуются инструментами ручного
        труда: электронные таблицы, расписания и Google-диски. Настало время меняться.
      </ReviewComponent>

      <AboutComponent
        title='Контролируйте показатели каждого франчайзи и находите точки роста  '
        description='Гибкая система ролей и удобное управление доступами
      в CRM-системе позволит головной компании быть в курсе происходящего внутри филиала. Невозможность копирования контента обеспечит сохранность данных. 
      В Параплан CRM клиентская база защищена от удаления, пользователям доступен только перенос клиентов в архив. '
        image={require("../images/franchise/team@2x.png")}
        imageWidth={673}
        imageHeight={340}
        reverse={true}
        bgSize={"hanging-circle"}
        color='#03C4A4'/>

      <ContactFormComponent/>
      <FranchiseAccordionComponent/>
    </Layout>
  )
}

export default FranchisePage
