import React from "react"

import styles from "./review.module.scss"

const ReviewComponent = ({ author, children, color, className }) => (
<div className={`${styles.review} ${className || ""}`} style={color ? {backgroundColor: color} : {}}>
    <div className={styles.text}>{children}</div>
    <div className={styles.author}>{author}</div>
  </div>
)

export default ReviewComponent