import React from "react"

import styles from "./radio.module.scss"

const Radio = ({ children, defaultChecked, onChange, name, checked }) => (
  <label className={styles.radio}>
    <input type='radio'
           name={name}
           checked={checked}
           defaultChecked={defaultChecked}
           onChange={onChange}/>
    <div className={styles.control}/>
    <div className={styles.label}>{children}</div>
  </label>
)

export default Radio
