import { CALCULATOR_STEPS } from "./calculator-price"
import { pluralize } from "../../../../utils/pluralize"

export function formatAmount(amount) {
  const step = CALCULATOR_STEPS[amount]
  switch (step.amount) {
    case 1:
    case 2:
      return `${step.amount} ${pluralize(step.amount, `филиал`, `филиала`, `филиалов`)}`
    default:
      return `до ${step.amount} ${pluralize(step.amount, `филиала`, `филиалов`, `филиалов`)}`
  }
}

export function formatDuration(duration) {
  switch (duration) {
    case "month":
      return "1 месяц"
    case "quarter":
      return "3 месяца"
    case "halfYear":
      return "6 месяцев"
    case "year":
      return "1 год"
    default:
      return ""
  }
}

export function getFullPrice(duration, amount, currency = "ru") {
  const step = CALCULATOR_STEPS[amount]
  return step.price[currency]["month"]
}

export function getPriceForPeriod(duration, amount) {
  const durations = {
    month: 1,
    quarter: 3,
    halfYear: 6,
    year: 12
  }
  return durations[duration] * getDiscountedPrice(duration, amount)
}

export function getDiscountedPrice(duration, amount, currency = "ru") {

  const step = CALCULATOR_STEPS[amount]
  return +step.price[currency][duration]
}

export function getDiscount(duration, amount, currency = "ru") {
  return getFullPrice(duration, amount, currency) - getDiscountedPrice(duration, amount, currency)
}

export function formatPrice(price) {
  return (price || 0).toLocaleString("en").replace(/,/g, " ")
}

export function formatCalculatorMessage(amount, duration, currency = "ru") {
  return `Тариф Франшизы - ${formatDuration(duration)}`
}

export function createLink(duration, amount) {
  const link = 'https://pay.alfabank.ru/payment/constructor/prepay.html'
  const enc = (json) => encodeURI(JSON.stringify(json))
  const params = [
    'login=paraplancrm',
    'logo=1',
    `def=${enc({
      name: "amount",
      value: `${getPriceForPeriod(duration, amount)}`,
      title: formatCalculatorMessage(amount, duration)
    })}`,
    `def=${enc({
      name: "orderNumber",
      value: 'Тариф Франшизы',
      title: "Тариф",
      state: 1
    })}`,
    `def=${enc({
      name: "description",
      value: formatDuration(duration),
      title: "Период",
      state: 1
    })}`,
    `ask=${enc({
      name: "Номер аккаунта",
      tip: "Номер аккаунта клиента можно найти в настройках",
      state: 2
    })}`
  ]
  return `${link}?${params.join('&')}`
}